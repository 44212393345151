.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-loader {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
}

.status-loader {
    left: 0px;
    width: 100%;
    position: absolute;
    display: block;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.7);
}

.input-field {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.input-field>label {
    color: #1e1e1e;
    position: absolute;
    top: -12px;
}